<template>
  <div class="drive">
    <div class="drive-item" v-for="(item, idx) in driveData" :key="idx">
        <div class="drive-item-box">
            <funnel-plot :driveData="item"></funnel-plot>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      driveData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {
      }
    },
    methods: {

    },
    mounted () {

    }
  }
</script>

<style lang='sass' scoped>
.drive
  &-item
    display: inline-block
    position: relative
    width: 100%
    height: 361px
    vertical-align: top
    &-box
      height: 100%
      width: 100%
      margin: 0 auto
      max-width: 400px
</style>
